import { withErrorBoundary, Spinner, NoResults } from 'shared';
import { MissingImage } from 'customizations';
import classNames from 'classnames';
import { absolutizePath } from 'shared/util';
import ParkCardBadge from './ParkCardBadge';
import ParkReservationSlots from './ParkReservationSlots';
import { useEffect, useState, useMemo } from 'react';
import { SmartButtonLink } from 'ui/smart-buttons';
import ParkReservationTimeSlot from 'api/park-reservation-time-slot'


function ParkCard({ park, base = park.baseCollectionName, selectedDate }) {
  const [timeSlots, setTimeSlots] = useState([]);
  const [loading, setLoading] = useState(true);

  let isCommunityPage = application.path.includes('/community-calendar') ||
    application.path.includes('/places-calendar') ||
    application.path.includes('/mobile-places-calendar') ||
    application.path.includes('/admin-places-calendar');

  let isAdminCommunityPage = application.path.includes('/admin-places-calendar');

  let isMobileCommunityPage = application.path.includes('/mobile-places-calendar')
  const date = useMemo(() => moment(selectedDate || application.todayDateString), [selectedDate]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let data;
        if (isCommunityPage && !isMobileCommunityPage) {
          data = application.path.includes('/admin-places-calendar')
            ? await ParkApi.loadAdminTimeSlotsForDate(park, date, null)
            : await ParkApi.loadTimeSlotsForDate(park, date, null);
        }
        if (isMobileCommunityPage) {
          const json = await Backend.get(`/places/${park.id}/reservations/${date.format("YYYY-MM-DD")}/mobile_available_timetable`);
          data = json.time_slots.map(ts => new ParkReservationTimeSlot({ ...ts, date: date }));
        }

        setTimeSlots(data);
      } catch (error) {
        console.error("Failed to fetch time slots:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [isCommunityPage, isMobileCommunityPage, park, date]);


  const renderItem = (slot) => {
    let { time, reservation, available, militaryTime } = slot;
    let options = {};

    if (reservation) {
      options = {
        text: (
          <>
            Cancel: {slot.desc}
            {reservation.user_name && <br />}{reservation.user_name}
            {reservation.note && <br />}{reservation.note}
          </>
        ),
        className: classNames('btn-sm park-reservation-btn', { gray: true }),
        key: time,
      };
    } else {
      options = {
        text: (
          <>
            {available ? "Open: ": "Reserved: " }{slot.desc}<br />
            {slot.res_user_name && <br />}{slot.res_user_name}
            {slot.res_note && <br />}{slot.res_note}
          </>
        ),
        className: classNames('btn-sm park-reservation-btn', { gray: available? false : true }),
        key: time,
      };
    }

    let url = park.uid
      ? `${absolutizePath(base)}/${park.uid}?start_date=${date.format("YYYY-MM-DD")}`
      : `${absolutizePath(base)}/park:${park.id}?start_date=${date.format("YYYY-MM-DD")}`;

    return <SmartButtonLink to={url} {...options} click={null} />;
  };

  return (
    <custom-card className={classNames('ParkCard', park.has_events && 'with-events')}>
      <Link to={park.uid
          ? `${absolutizePath(base)}/${park.uid}?start_date=${date.format("YYYY-MM-DD")}`
          : `${absolutizePath(base)}/park:${park.id}?start_date=${date.format("YYYY-MM-DD")}`
        } className="plain-link">

        <div className="card">
          <div className="card-img-container">
            <img
              className="card-img-top"
              src={park.image_preview_url || MissingImage}
              alt={park.name}
            />
            <ParkCardBadge park={park} />
          </div>
          <div className="card-meta">
            <div className="card-note float-right">{park.opening_status_str}</div>
            <h5 className="card-heading">{park.name}</h5>
          </div>
        </div>
      </Link>
      {isCommunityPage && (
        <div className="park-reservation-time-slots">
          {loading ? (
            <Spinner />
          ) : timeSlots.length ? (
            timeSlots.map((ts) => renderItem(ts))
          ) : (
            isAdminCommunityPage ?
              <NoResults className="compact">No Timetables Enabled</NoResults>
              : <NoResults className="compact">No Availability Today</NoResults>
          )}
        </div>
      )}
    </custom-card>
  );

}

export default withErrorBoundary(ParkCard);
