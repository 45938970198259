import { GradientedScroller, connected, withErrorBoundary, NoResults} from 'shared'
import { TopLevelPage, ModelGrid } from 'ui'
import EventCard from 'ui/events/EventCard'
import ParkCard from 'ui/parks/ParkCard'
import { EventApi, AnnouncementApi, Park, ParkApi, Menu, User } from 'api'
import { DateRangePicker } from 'react-date-range';
import { addDays, format } from 'date-fns';
import { useEffect, useState } from 'react';
import Announcements from '../events/Announcements'

calendarGlobal = []
intervalId = null

AdminPlacesCalender = ({match}) ->
  feedItemId = match.params.id
  [calendar, setCalendar] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  [adminPlaces, setAdminPlaces] = useState([])
  [selectedDate, setSelectedDate] = useState(new Date())
  [tags, setTags] = useState([])
  [selectedTag, setSelectedTag] = useState('')

  parseDate = (dateTime) =>
    format(dateTime, "yyyy'-'MM'-'dd")

  dateParams = (calendar) =>
    {
      start_date: parseDate(calendar.startDate),
      end_date: parseDate(calendar.endDate),
      tag: selectedTag
    }

  loadTags = () =>
    ParkApi.getCalendarTags().then (data) =>
      setTags(data.tags)
      defaultTag = data.defaultTag
      
      if !selectedTag and defaultTag
        setSelectedTag(defaultTag.id)


  loadData = (calendar = null) =>
    calendar = { startDate: new Date(), endDate: new Date() } unless calendar
    ParkApi.adminPlaces(dateParams(calendar)).then (data) =>
      setAdminPlaces(data)

  updateDateRange = (range) =>
    setSelectedDate(range.selection.startDate)
    setCalendar([range.selection])

  useEffect(() =>
    calendarGlobal = calendar
    loadTags()
    loadData(calendarGlobal[0])
    return # Keep this to prevent useEffect cleanup warnings
  , [calendar, selectedTag])

  handleTagChange = (event) =>
    setSelectedTag(event.target.value)

  <TopLevelPage className="AdminPlacesCalender" modelId={feedItemId} base="/admin-places-calendar">
    <div className="row calendar-section justify-content-center mt-3">
      <div className="col text-center">
        <DateRangePicker
          onChange={updateDateRange}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={[{ startDate: selectedDate, endDate: selectedDate, key: 'selection' }]}
          direction="horizontal"
          inputRanges={[]}
          editableDateInputs={false}
        />
      </div>
    </div>  
    <section>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <h1 className="section-heading-lowercase my-3">Admin Community Reservations</h1>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="mb-4">
          <select
            className="form-select border-primary text-center py-2 px-4 rounded shadow-sm"
            onChange={handleTagChange} value={selectedTag}
          >
            <option value="">Select a Tag</option>
            {tags.map((tag) => (
              <option key={tag.id} value={tag.id}>
                {tag.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="row">
        { if (adminPlaces.length == 0)
            <NoResults>All reservable amenities are currently reserved for today.</NoResults>
          else
            for model in adminPlaces
              <div className="col-sm-6 col-lg-4 col-xl-3" key={model.uid}>
                <ParkCard park={model} base='/admin-places-calendar' selectedDate={format(selectedDate, "yyyy-MM-dd")}/>
              </div>
        }
      </div>
    </section>

  </TopLevelPage>

export default withErrorBoundary connected AdminPlacesCalender
