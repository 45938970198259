import { GradientedScroller, connected, withErrorBoundary, NoResults} from 'shared'
import { TopLevelPage, ModelGrid } from 'ui'
import EventCard from 'ui/events/EventCard'
import ParkCard from 'ui/parks/ParkCard'
import { EventApi, AnnouncementApi, Park, ParkApi, Menu, User } from 'api'
import { DateRangePicker } from 'react-date-range';
import { addDays, format } from 'date-fns';
import { useEffect, useState } from 'react';
import Announcements from '../events/Announcements'

calendarGlobal = []
intervalId = null

PlacesCalenderView = ({match}) ->
  feedItemId = match.params.id
  [calendar, setCalendar] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  [openAvailable, setOpenAvailable] = useState([])
  [selectedDate, setSelectedDate] = useState(new Date())

  parseDate = (dateTime) =>
    format(dateTime, "yyyy'-'MM'-'dd")

  dateParams = (calendar) =>
    {
      start_date: parseDate(calendar.startDate),
      end_date: parseDate(calendar.endDate)
    }

  loadData = (calendar = null) =>
    calendar = { startDate: new Date(), endDate: new Date() } unless calendar
    ParkApi.openAvailable(dateParams(calendar)).then (data) =>
      setOpenAvailable(data)

  updateDateRange = (range) =>
    setSelectedDate(range.selection.startDate)
    setCalendar([range.selection])

  useEffect(() =>
    if !User.signedIn && App.forceGuestMode
      User.guestSignInWithOutRedirection()

    return
  , [])

  useEffect(() => 
    calendarGlobal = calendar
    loadData(calendarGlobal[0]) if User.signedIn && !User.current.isGuestExperience
    return # Keep this to prevent useEffect cleanup warnings
  , [calendar])

  <TopLevelPage className="PlacesCalender" modelId={feedItemId} base="/places-calendar">
    <div className="row calendar-section justify-content-center mt-3">
      <div className="col text-center">
        <DateRangePicker
          onChange={updateDateRange}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={[{ startDate: selectedDate, endDate: selectedDate, key: 'selection' }]}
          direction="horizontal"
          inputRanges={[]}
          editableDateInputs={false}
        />
      </div>
    </div>  
    <section>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <h1 className="section-heading-lowercase my-3">Community Reservations</h1>
        </div>
      </div>

      <div className="row">
        { if (openAvailable.length == 0)
            <NoResults>All reservable amenities are currently reserved for today.</NoResults>
          else
            for model in openAvailable
              <div className="col-sm-6 col-lg-4 col-xl-3" key={model.uid}>
                <ParkCard park={model} base='/places-calendar' selectedDate={format(selectedDate, "yyyy-MM-dd")}/>
              </div>
        }
      </div>
    </section>

  </TopLevelPage>

export default withErrorBoundary connected PlacesCalenderView
