import { Api } from 'base'
import Park from './park'
import ParkReservationTimeSlot from './park-reservation-time-slot'

class ParkApi extends Api {
  collectionName = 'parks'

  async index(params) {
    let json = await Backend.get('/places', params)
    return this.state.triggerNone(_ =>
      json.places.map(r => new Park(r))
    )
  }

  async list(params) {
    let json = await Backend.get('/places/list', params)
    return this.state.triggerNone(_ =>
      json.places.map(r => new Park(r))
    )
  }

  async mine() {
    let json = await Backend.get('/places/mine')
    return this.state.triggerNone(_ =>
      json.places.map(r => new Park(r))
    )
  }

  async reserved(params) {
    let json = await Backend.get('/places/reserved', params)
    return this.state.triggerNone(_ =>
      json.places.map(r => new Park(r))
    )
  }
  async adminPlaces(params) {
    let json = await Backend.get('/places/admin_places', params)
    return this.state.triggerNone(_ =>
      json.places.map(r => new Park(r))
    )
  }

  async openAvailable(params) {
    let json = await Backend.get('/places/available', params)
    return this.state.triggerNone(_ =>
      json.places.map(r => new Park(r))
    )
  }

  async getCalendarTags() {
    let json = await Backend.get('/tags/calendar_tags');
    
    const tags = json.tags.map(tag => ({ id: tag.id, name: tag.name }));
    const defaultTag = json.default_tag ? { id: json.default_tag.id, name: json.default_tag.name } : null;

    return this.state.triggerNone(_ => ({
      tags, defaultTag
    }));
  }

  async show(id) {
    return this.receiveModel(await Backend.get(`/places/${id}`))
  }

  async enroll(retail) {
    return this.receiveModel(await Backend.post(`/retails/${retail.id}/membership`))
  }

  async leave(retail) {
    return this.receiveModel(await Backend.destroy(`/retails/${retail.id}/membership`))
  }

  receiveModel(json) {
    let model = new Park(json.place)
    this.state.updateModel(model)
    return model
  }

  async loadTimeSlotsForDate(park, date, serviceId) {
    const params = _.blank(serviceId) ? { } : { service_id: serviceId }
    let json = await Backend.get(`/places/${park.id}/reservations/${date.format("YYYY-MM-DD")}/timetable`, params)
    return json.time_slots.map(ts => new ParkReservationTimeSlot({ ...ts, date: date }))
  }

  async loadAdminTimeSlotsForDate(park, date, serviceId) {
    const params = { admin: true }
    let json = await Backend.get(`/places/${park.id}/reservations/${date.format("YYYY-MM-DD")}/timetable`, params)
    return json.time_slots.map(ts => new ParkReservationTimeSlot({ ...ts, date: date }))
  }

  async reserveTimeSlot(park, timeSlot, options, serviceId) {
    return this.convertRemoteError(async () => {
      let json = await Backend.post(`/places/${park.id}/reservations/${timeSlot.date.format("YYYY-MM-DD")}/${timeSlot.time}`, { options, service_id: serviceId })
      this.state.updateModel(new Park(json.place))
      return json.time_slots.map(ts => new ParkReservationTimeSlot({ ...ts, date: timeSlot.date }))
    })
  }

  async cancelTimeSlot(park, timeSlot) {
    return this.convertRemoteError(async () => {
      let json = await Backend.destroy(`/places/${park.id}/reservations/${timeSlot.date.format("YYYY-MM-DD")}/${timeSlot.time}`)
      this.state.updateModel(new Park(json.place))
      return json.time_slots.map(ts => new ParkReservationTimeSlot({ ...ts, date: timeSlot.date }))
    })
  }

  async getFullyReservedDates(park, month, serviceId) {
    let json = await Backend.get(`/places/${park.id}/reservations/${month}/reserved_dates`, { service_id: serviceId })
    return json.reserved_out_dates
  }

  async getWorkflowStatus(park) {
    let json = await Backend.get(`/places/${park.id}`)
    return json.place.workflow_complete
  }
}

export default new ParkApi
